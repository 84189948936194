<template>
<DateRangePicker
  v-bind="$attrs"
  v-on="$listeners"
  v-model="getValue"
  single-date-picker
  auto-apply
  :ranges="false"
  controlContainerClass=""
>
  <template #input="picker">
    <div class="cursor-pointer">
      <slot name="selected" :selected="picker">
        {{ picker.startDate ? $moment(picker.startDate).format(FORMAT_DATE) : $t('DATEPICKER.NO_DATE_SELECTED') }}
      </slot>
    </div>
  </template>
</DateRangePicker>
</template>
<script>
import { FORMAT_DATE } from '@/helpers/validation'

export default {
  name: 'DateSinglePickerUI',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: null,
    format: {
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss',
    },
  },
  data() {
    return {
      FORMAT_DATE,
    }
  },
  computed: {
    getValue: {
      get() {
        return this.value ? {
          startDate: this.value,
          endDate: this.value, 
        } : {}
      },
      set(val) {
        this.$emit('input', this.$moment(val.startDate).format(this.format))
      },
    },
  },
}
</script>
