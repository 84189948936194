<template>
<b-container fluid class="p-0">
  <!-- begin: Summary-->
  <b-row>
    <template v-if="!isEdit">
      <b-col cols="12">
        <div class="d-block d-sm-flex justify-content-between">
          <div class="mb-3 mb-sm-0">
            <div class="font-weight-bold mb-2">{{ $t('ORDERS.STATUS') }}</div>
            <b-dropdown
              size="lg"
              variant="link"
              class="d-block d-md-inline-block"
              toggle-class="text-decoration-none p-0 border-0 w-100"
              no-caret
              right
              no-flip
            >
              <template #button-content>
                <div class="label label-xl label-inline pr-0 w-100" style="height: 32px;" :class="`label-${getColorStatus}`">
                  {{ $t(`ORDER.STATUSES.${item.status}`) || $t('BASE.NOT_SELECTED') }}
                  <div class="btn btn-icon btn-sm btn-dropdown p-0">
                    <span class="svg-icon svg-icon-sm svg-icon-light">
                      <inline-svg src="/media/svg/icons/Navigation/Angle-down.svg" />
                    </span>
                  </div>
                </div>
              </template>
              <b-dropdown-item
                v-for="status in statuses"
                :key="status.val"
                :active="status.val === item.status"
                @click="changeStatus(status.val)"
                active-class="info"
              >
                {{ $t(`ORDER.STATUSES.${status.val}`) }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="d-flex justify-content-end mx-n3">
            <div class="flex-grow-1 flex-sm-grow-0 text-md-right px-3">
              <div class="text-md-right font-weight-bold mb-2">{{ $t('ORDER.START_DATE') }}</div>
              <DateSinglePickerUI
                class="d-block d-md-inline-block"
                :opens="$store.getters.isMobile ? 'right' : 'left'"
                v-model="form.createdAt"
              >
                <template #selected>
                  <div
                    class="d-flex d-md-inline-flex align-items-center justify-content-center btn btn-light-primary btn-sm font-weight-bold"
                    style="min-width: 120px; height: 32px;"
                  >
                    {{ form.createdAt ? $moment(form.createdAt).format('DD MMM, yyyy') : $t('DATEPICKER.NO_DATE_SELECTED') }}
                  </div>
                </template>
              </DateSinglePickerUI>
            </div>
            <div class="flex-grow-1 flex-sm-grow-0 text-md-right px-3">
              <div class="text-md-right font-weight-bold mb-2">{{ $t('ORDER.DUE_DATE') }}</div>
              <DateSinglePickerUI
                class="d-block d-md-inline-block"
                opens="left"
                v-model="form.deadline"
              >
                <template #selected>
                  <div
                    style="min-width: 120px; height: 32px;"
                    class="d-flex d-md-inline-flex align-items-center justify-content-center btn btn-danger btn-sm font-weight-bold"
                  >
                    {{ form.deadline ? $moment(form.deadline).format('DD MMM, yyyy') : $t('DATEPICKER.NO_DATE_SELECTED') }}
                  </div>
                </template>
              </DateSinglePickerUI>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="6" lg="4" class="my-6">
        <h4 class="text-dark font-weight-bold mb-3">{{ $t('ORDER.SUMMARY.CLIENT_INFO') }}</h4>
        <div class="d-flex align-items-center">
          <div class="mr-10">
            <div class="font-weight-bolder mb-2">{{ $t('ORDER.SUMMARY.NAME') }}</div>
            <div class="opacity-70" v-if="item.client">{{ item.client.firstName }} {{ item.client.lastName }}</div>
          </div>
          <div>
            <div class="font-weight-bolder mb-2">{{ $t('ORDER.SUMMARY.PHONE') }}</div>
            <div class="opacity-70" v-if="item.client">{{ item.client.phone }}</div>
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="6" lg="8" class="my-6">
        <h4 class="text-dark font-weight-bold mb-3">{{ $t('ORDER.SUMMARY.ORDER_WAREHOUSE') }}</h4>
        <b-container fluid class="p-0">
          <b-row>
            <b-col cols="4">
              <div class="d-flex flex-column flex-sm-row align-items-center justify-content-center flex-lg-fill my-1">
                <span class="svg-icon svg-icon-primary svg-icon-3x mr-0 mr-sm-4">
                  <inline-svg src="/media/svg/icons/Clothes/T-Shirt.svg" />
                </span>
                <div class="d-flex flex-column text-dark-75 align-items-center align-items-sm-center">
                  <span class="font-weight-bolder font-size-sm">{{ $t('ORDER.ITEMS') }}</span>
                  <span class="text-dark-75 font-weight-bolder font-size-lg">{{ item.quantities ? item.quantities.itemsCount : 0 }}</span>
                </div>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="d-flex flex-column flex-sm-row align-items-center justify-content-center flex-lg-fill my-1">
                <span class="svg-icon svg-icon-primary svg-icon-3x mr-0 mr-sm-4">
                  <inline-svg src="/media/svg/icons/Tools/Roller.svg" />
                </span>
                <div class="d-flex flex-column text-dark-75 align-items-center align-items-sm-center">
                  <span class="font-weight-bolder font-size-sm">{{ $t('ORDER.SERVICES') }}</span>
                  <span class="text-dark-75 font-weight-bolder font-size-lg">{{ item.quantities ? item.quantities.servicesCount : 0 }}</span>
                </div>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="d-flex flex-column flex-sm-row align-items-center justify-content-center flex-lg-fill my-1">
                <span class="svg-icon svg-icon-primary svg-icon-3x mr-0 mr-sm-4">
                  <inline-svg src="/media/svg/icons/Shopping/Box2.svg" />
                </span>
                <div class="d-flex flex-column text-dark-75 align-items-center align-items-sm-center">
                  <span class="font-weight-bolder font-size-sm">{{ $t('ORDER.PRODUCTS') }}</span>
                  <span class="text-dark-75 font-weight-bolder font-size-lg">{{ item.quantities ? item.quantities.productsCount : 0 }}</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col cols="12" class="text-right">
        <b-button
          v-if="!(item.discounts && item.discounts.length > 4)"
          @click="showDiscountFormCreate"
          class="btn btn-light-success font-weight-bold btn-sm"
        >
          <span class="svg-icon btn-light-success">
            <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
          </span>
          {{ $t('ORDER.SUMMARY.ADD_DISCOUNT') }}
        </b-button>
      </b-col>
      <b-col cols="12" md="6" lg="6" xl="3" class="my-6">
        <h4 class="text-dark font-weight-bold mb-3">{{ $t('ORDER.SUMMARY.TOTAL_COST') }}</h4>
        <div class="text-dark-75 font-weight-bolder font-size-lg">{{ item.costWithoutDiscount | centsToDollars }}</div>
      </b-col>
      <b-col cols="12" md="6" lg="6" xl="3" class="my-6">
        <h4 class="text-dark font-weight-bold mb-3">{{ $t('ORDER.SUMMARY.DISCOUNTS') }}</h4>
        <div class="d-flex flex-wrap">
          <div
            v-for="discount in item.discounts"
            :key="discount.uuid"
            :class="discount.type === 'absolute' ? 'btn-info' : 'btn-warning'"
            class="btn btn-sm pr-1 mr-3 mb-3 d-flex align-items-center position-relative"
          >
            <span
              @click="showDiscountForm(discount)"
            >
              style="line-height: 12px;"
              class="d-flex align-items-center pr-2 cursor-pointer"
              <template v-if="discount.type === 'absolute'">
                {{ discount.value | centsToDollars }}
              </template>
              <template v-else>
                {{ discount.value }}
              </template>
              <span v-if="discount.type === 'percent'" class="svg-icon svg-icon-light svg-icon-sm">
                <inline-svg src="/media/svg/icons/Shopping/Sale1.svg" />
              </span>
              <span v-else class="svg-icon svg-icon-light svg-icon-sm">
                <inline-svg src="/media/svg/icons/Shopping/Price1.svg" />
              </span>
            </span>
            <span
              @click="onRemoveDiscount(discount)"
              style="cursor:pointer; top: -8px; right: -8px;"
              class="bg-secondary rounded svg-icon svg-icon-secondary svg-icon-sm position-absolute"
            >
              <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
            </span>
          </div>
        </div>
      </b-col>
      <b-col cols="12" md="6" lg="6" xl="3" class="my-6">
        <h4 class="text-dark font-weight-bold mb-3">{{ $t('ORDER.SUMMARY.FINAL_COST') }}</h4>
        <div class="text-dark-75 font-weight-bolder font-size-lg">{{ item.costWithDiscount | centsToDollars }}</div>
      </b-col>
      <b-col cols="12" md="6" lg="6" xl="3" class="my-6">
        <h4 class="text-dark font-weight-bold mb-3">{{ $t('ORDER.PREPAYMENT') }}</h4>
        <div class="text-success font-size-h3 font-weight-boldest">
          <b-form-group id="input-group-1">
            <InputCurrency
              id="input-1"
              v-model="$v.form.prepayment.$model"
              class="form-control-sm bg-white text-dark-75 font-weight-bolder font-size-lg"
              :placeholder="$t('PLACEHOLDER.VALUE')"
              trim
              @input="clearServerError('prepayment')"
              aria-describedby="input-1-feedback"
            />
            <b-form-invalid-feedback id="input-1-feedback">
              <template v-if="serverErrors.prepayment">{{ serverErrors.prepayment[0] }}</template>
              <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.PREPAYMENT') }) }}</template>
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
      </b-col>
    </template>
    <template v-if="isEdit">
      <b-col cols="12">
        <div class="d-flex flex-wrap justify-content-between align-items-center mb-6">
          <h4 class="text-dark font-weight-bold">{{ $t('ORDER.SUMMARY.DISCOUNTS') }}</h4>
          <b-button
            v-if="!(item.discounts && item.discounts.length > 4)"
            @click="showDiscountFormCreate"
            class="btn btn-light-success font-weight-bold btn-sm"
          >
            <span class="svg-icon btn-light-success">
              <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
            </span>
            {{ $t('ORDER.SUMMARY.ADD_DISCOUNT') }}
          </b-button>
        </div>
        <div class="d-flex flex-wrap">
          <div v-for="discount in item.discounts"
               :key="discount.uuid"
               :class="discount.type === 'absolute' ? 'btn-info' : 'btn-warning'"
               class="btn btn-sm pr-1 mr-3 mb-3 d-flex align-items-center position-relative"
          >
            <span
              @click="showDiscountForm(discount)"
              style="line-height: 12px;"
              class="d-flex align-items-center pr-2 cursor-pointer"
            >
              <template v-if="discount.type === 'absolute'">
                {{ discount.value | centsToDollars }}
              </template>
              <template v-else>
                {{ discount.value }}
              </template>
              <span v-if="discount.type === 'percent'" class="svg-icon svg-icon-light svg-icon-sm">
                <inline-svg src="/media/svg/icons/Shopping/Sale1.svg" />
              </span>
              <span v-else class="svg-icon svg-icon-light svg-icon-sm">
                <inline-svg src="/media/svg/icons/Shopping/Price1.svg" />
              </span>
            </span>
            <span
              @click="onRemoveDiscount(discount)"
              style="cursor:pointer; top: -8px; right: -8px;"
              class="bg-secondary rounded svg-icon svg-icon-secondary svg-icon-sm position-absolute"
            >
              <inline-svg src="/media/svg/icons/Navigation/Close.svg" />
            </span>
          </div>
        </div>
      </b-col>
    </template>
    <b-col cols="12">
      <div class="d-flex flex-wrap justify-content-between align-items-center mb-6">
        <h4 class="text-dark font-weight-bold">{{ $t('ORDER.PAYMENTS.TITLE') }}</h4>
        <b-button
          class="btn btn-light-success font-weight-bold btn-sm"
          @click="showPaymentFormCreate"
        >
          <span class="svg-icon btn-light-success">
            <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
          </span>
          {{ $t('ORDER.SUMMARY.ADD_PAYMENT') }}
        </b-button>
      </div>

      <Table :fields="fieldsPayments" :items="item.payments">
        <template #cell(sum)="option">
          {{ option.item.sum | centsToDollars }}
        </template>
        <template #cell(type)="option">
          {{ $t(`ORDER.PAYMENTS.${option.item.type.toUpperCase()}`).toUpperCase() }}
        </template>
        <template #cell(method)="option">
          {{ $t(`ORDER.PAYMENTS.${option.item.method.toUpperCase()}`).toUpperCase() }}
        </template>
        <template #cell(actions)="option">
          <b-button
            class="btn btn-icon btn-light-info btn-sm mr-3" @click="showPaymentForm(option.item)"
          >
            <span class="svg-icon svg-icon-md btn-light-info">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </b-button>
          <div class="btn btn-icon btn-light-danger btn-sm" @click="onDeletePayment(option.item)">
            <span class="svg-icon svg-icon-md btn-light-info">
              <inline-svg src="/media/svg/icons/Home/Trash.svg" />
            </span>
          </div>
        </template>
      </Table>
    </b-col>
    <b-col cols="12">
      <h4 class="text-dark font-weight-bold mt-3">{{ $t('ORDER.SUMMARY.COMMENT') }}</h4>
      <b-form-group label-for="input-comment">
        <Textarea
          id="input-comment"
          v-model="$v.form.comment.$model"
          :state="validateState('comment')"
          :placeholder="$t('PLACEHOLDER.COMMENT')"
          @input="clearServerError('COMMENT')"
          aria-describedby="input-comment-feedback"
        />
        <b-form-invalid-feedback id="input-comment-feedback">
          <template v-if="serverErrors.comment">{{ serverErrors.comment[0] }}</template>
        </b-form-invalid-feedback>
      </b-form-group>
    </b-col>
    <b-col cols="12" class="mt-6 d-flex flex-column flex-sm-row justify-content-between">
      <div class="d-flex flex-column flex-sm-row">
        <b-dropdown
          class="pr-0 pr-sm-1"
          size="xl"
          variant="btn"
          toggle-class="text-decoration-none p-0 mb-4 mb-sm-0"
          no-caret
          right
          no-flip
        >
          <template #button-content>
            <div class="label label-xl label-inline label-success w-100" style="height: calc(1.35em + 1.1rem + 1px);">
              {{ $t('ORDER.RECEIPT') }}
            </div>
          </template>
          <b-dropdown-item @click="printAll">{{ $t('ORDER.PRINT_ALL') }}</b-dropdown-item>
          <b-dropdown-item @click="printMain">{{ $t('ORDER.PRINT_MAIN') }}</b-dropdown-item>
          <b-dropdown-item @click="printSecondary">{{ $t('ORDER.PRINT_SECONDARY') }}</b-dropdown-item>
        </b-dropdown>
        <show-qr-code-button :has-active-bot="hasActiveBot" :client-uuid="$route.query.client" class="mb-4 mb-sm-0" />
      </div>
      <div class="d-flex flex-column flex-sm-row">
        <div class="btn btn-secondary font-weight-bold btn-sm mr-0 mb-4 mb-sm-0 mr-sm-3" @click="goBack">
          {{ $t('BASE.BACK') }}
        </div>
        <b-button :disabled="loading" type="submit" size="sm" variant="info" @click="onSubmit">
          <b-spinner v-if="loading" small variant="light" />
          {{ $t('FORM.SUBMIT') }}
        </b-button>
      </div>
    </b-col>
  </b-row>
  <OrderDiscountForm v-if="isShow && isQuickPanel && getPanelName === 'OrderDiscountForm'" :itemEdit="itemEditDiscount" @onSubmit="addDiscount" />
  <OrderPaymentsForm
    v-if="isShowPaymentPanel && isQuickPanel && getPanelName === 'OrderPaymentsForm'"
    :itemEdit="itemEditPayment"
    @onSubmit="onAddPayment"
  />
</b-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { FETCH_ORDER } from '@/core/services/store/order.module'
import { validationMixin } from 'vuelidate'
import RepoOrder from '@/core/repository/company/ordersRepository'
import RepoDiscount from '@/core/repository/company/discountRepository'
import RepoPayment from '@/core/repository/company/orderPaymentsRepository'
import { DATE } from '@/helpers/validation'
import saveAs from 'save-as'
import ShowQrCodeButton from '@/components/bot/ShowQrCodeButton'
import SettingsRepo from '@/core/repository/company/settingsRepository'
import { STATUSES } from '@/constants/statuses-order'
import serverVuelidate from '../../../mixins/serverVuelidate'
import OrderDiscountForm from './UI/OrderDiscountForm'
import Textarea from '../../forms-items/Textarea'
import InputCurrency from '../../forms-items/inputCurrency'
import DateSinglePickerUI from '../../UI/DateSinglePickerUI'
import OrderPaymentsForm from './UI/OrderPaymentsForm.vue'
import Table from '../../UI/Table'

export default {
  name: 'SummaryOrder',
  components: {
    DateSinglePickerUI,
    InputCurrency,
    Textarea,
    OrderDiscountForm,
    ShowQrCodeButton,
    OrderPaymentsForm,
    Table,
  },
  mixins: [validationMixin, serverVuelidate],
  props: {
    isEdit: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        comment: '',
        prepayment: 0,
        deadline: null,
        createdAt: '',
      },
      hasActiveBot: false,
      isShow: false,
      isShowPaymentPanel: false,
      item: {},
      loading: false,
      items: [],
      statuses: STATUSES,
      itemsFooter: [{}],
      fieldsPayments: [
        {
          key: 'uuid',
          label: '#',
          sortable: false,
        },
        {
          key: 'sum',
          label: this.$t('ORDER.PAYMENTS.SUM'),
          sortable: false,
        },
        {
          key: 'type',
          label: this.$t('ORDER.PAYMENTS.TYPE'),
          sortable: false,
        },
        {
          key: 'method',
          label: this.$t('ORDER.PAYMENTS.METHOD'),
          sortable: false,
        },
        {
          key: 'dateTime',
          label: this.$t('ORDER.PAYMENTS.DATETIME'),
          sortable: false,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ],
      fieldsFooter: [
        {
          key: 'total',
          label: this.$t('ORDER.SUMMARY.TOTAL_COST'),
          sortable: false,
        },
        {
          key: 'discount',
          label: this.$t('ORDER.SUMMARY.DISCOUNTS'),
          sortable: false,
        },
        {
          key: 'final',
          class: 'text-right',
          label: this.$t('ORDER.SUMMARY.FINAL_COST'),
          sortable: false,
        },
        {
          key: 'prepayment',
          class: 'text-right',
          label: this.$t('ORDER.PREPAYMENT'),
          sortable: false,
        },
      ],
      orderItems: [],
      itemEditDiscount: null,
      itemEditPayment: null,
      validations: {
        form: {
          comment: {},
          prepayment: {},
        },
      },
    }
  },
  computed: {
    ...mapState({
      order: (state) => state.order.item,
    }),
    ...mapGetters([
      'isQuickPanel',
      'getPanelName',
    ]),
    getColorStatus() {
      const obj = this.statuses.find((f) => f.val === this.item.status)
      if (obj) return obj.variant
      return 'light'
    },
  },
  watch: {
    '$route.query': {
      handler(query) {
        if (query.type === 'summary') {
          this.fetchData()
        }
      },
      deep: true,
      immediate: true,
    },
    order: {
      handler(o) {
        this.form.comment = o.comment
        this.form.prepayment = o.prepayment
        this.form.deadline = o.deadline
        this.form.createdAt = o.createdAt
      },
    },
  },
  mounted() {
    this.form.createdAt = this.$moment(this.form.createdAt).startOf('day').format(DATE)
    SettingsRepo.get()
      .then((settings) => {
        if (settings.activeTelegramBot) {
          this.hasActiveBot = true
        }
      })
      .catch(() => {})
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    changeStatus(val) {
      this.item.status = val
      RepoOrder.patch(this.$route.query.order, { status: this.item.status })
    },
    showDiscountFormCreate() {
      this.isShow = true
      this.$store.commit('setQuickPanelTitle', this.$t('ORDER.DISCOUNT.FORM_TITLE'))
      this.$store.commit('setQuickPanelName', 'OrderDiscountForm')
      this.$store.commit('onQuickPanelOpen')
    },
    showDiscountForm(itemEditDiscount) {
      this.itemEditDiscount = itemEditDiscount
      this.isShow = true
      this.$store.commit('setQuickPanelTitle', this.$t('ORDER.DISCOUNT.FORM_TITLE'))
      this.$store.commit('setQuickPanelName', 'OrderDiscountForm')
      this.$store.commit('onQuickPanelOpen')
    },
    showPaymentFormCreate() {
      this.isShowPaymentPanel = true
      this.$store.commit('setQuickPanelTitle', this.$t('ORDER.PAYMENTS.FORM_TITLE'))
      this.$store.commit('setQuickPanelName', 'OrderPaymentsForm')
      this.$store.commit('onQuickPanelOpen')
    },
    showPaymentForm(itemEditPayment) {
      this.itemEditPayment = itemEditPayment
      this.isShowPaymentPanel = true
      this.$store.commit('setQuickPanelTitle', this.$t('ORDER.PAYMENTS.FORM_TITLE'))
      this.$store.commit('setQuickPanelName', 'OrderPaymentsForm')
      this.$store.commit('onQuickPanelOpen')
    },
    onAddPayment() {
      this.itemEditPayment = null
      this.$store.commit('onQuickPanelClose')
      this.isShowPaymentPanel = false
      this.fetchData()
    },
    addDiscount(res) {
      if (this.itemEditDiscount) {
        this.onEditDiscount(res)
      } else {
        this.onAddDiscount(res)
      }
      this.itemEditDiscount = null
      this.$store.commit('onQuickPanelClose')
      this.isShow = false
    },
    onEditDiscount(item) {
      const index = this.item.discounts.findIndex((f) => f.uuid === item.uuid)
      if (index >= 0) {
        this.$set(this.item.discounts, index, item)
        this.reFetchData()
      }
    },
    onAddDiscount(res) {
      this.item.discounts.push(res)
      this.reFetchData()
    },
    onRemoveDiscount(item) {
      const index = this.item.discounts.findIndex((f) => f.uuid === item.uuid)
      if (index >= 0) {
        RepoDiscount.delete(item.uuid).then(() => {
          this.item.discounts.splice(index, 1)
          this.reFetchData()
        })
      }
    },
    onDeletePayment(item) {
      const index = this.item.payments.findIndex((f) => f.uuid === item.uuid)
      if (index >= 0) {
        RepoPayment.delete(item.uuid).then(() => {
          this.item.payments.splice(index, 1)
          this.fetchData()
        })
      }
    },
    reFetchData() {
      RepoOrder.patch(this.$route.query.order, { discounts: this.item.discounts.map((m) => m.uuid) }).then(() => { this.fetchData() })
    },
    fetchData() {
      this.$store.dispatch(FETCH_ORDER, this.$route.query.order).then((res) => {
        this.item = res
        this.form.comment = res.comment
        this.form.prepayment = res.prepayment
        this.form.createdAt = res.createdAt
        this.items = res.lines
        this.orderItems = res.items
      })
    },
    goBack() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          type: 'files',
        },
      })
    },
    printAll() {
      RepoOrder.printMergedReceipt(this.$route.query.order)
        .then(this.saveFile)
    },
    printMain() {
      RepoOrder.printReceipt(this.$route.query.order)
        .then(this.saveFile)
    },
    printSecondary() {
      RepoOrder.printCutReceipt(this.$route.query.order)
        .then(this.saveFile)
    },
    saveFile(response) {
      const headerLine = response.headers['content-disposition']
      const disposition = headerLine.split(';')
      let filename = 'output.pdf'
      if (disposition.length === 2) {
        filename = disposition[1].replace('filename=', '').trim()
      }
      const blob = new Blob([response.data])
      saveAs(blob, filename)
    },
    onSubmit() {
      this.clearServerErrors()
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.loading = true
      const obj = { ...this.form }
      RepoOrder.patch(this.$route.query.order, obj).then(() => {
        this.$router.push({ name: 'companyOrdersIndex' })
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.seServerErrors(err.response.data)
      })
    },
  },
}
</script>

<style scoped>

</style>
