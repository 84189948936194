<template>
<Portal to="OrderPaymentsForm">
  <div class="d-flex align-items-end flex-column h-100">
    <div class="w-100">
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <b-container fluid class="p-0">
          <b-row>
            <b-col cols="12">
              <b-form-group :label="`${$t('ORDER.PAYMENTS.SUM')}:`" label-for="input-1">
                <InputCurrency
                  id="input-1"
                  v-model="$v.form.sum.$model"
                  :state="validateState('sum')"
                  :placeholder="$t('PLACEHOLDER.VALUE')"
                  trim
                  @input="clearServerError('sum')"
                  aria-describedby="input-1-feedback"
                />
                <b-form-invalid-feedback id="input-1-feedback">
                  <template v-if="serverErrors.sum">{{ serverErrors.sum[0] }}</template>
                  <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('ORDER.PAYMENTS.SUM') }) }}</template>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group :label="`${$t('ORDER.PAYMENTS.TYPE')}:`" label-for="select-1">
                <SelectInfinityScroll
                  id="select-1"
                  v-model="$v.form.type.$model"
                  :search.sync="searchType"
                  :state="validateState('type') === false"
                  :items="optionsType"
                  clearable
                  @input="clearServerError('type')"
                  aria-describedby="select-1-feedback"
                />
                <b-form-invalid-feedback id="select-1-feedback" :class="{'d-block': validateState('type') === false}">
                  <template v-if="serverErrors.type">{{ serverErrors.type[0] }}</template>
                  <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('ORDER.PAYMENTS.TYPE') }) }}</template>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group :label="`${$t('ORDER.PAYMENTS.METHOD')}:`" label-for="select-2">
                <SelectInfinityScroll
                  id="select-2"
                  v-model="$v.form.method.$model"
                  :search.sync="searchType"
                  :state="validateState('method') === false"
                  :items="optionsMethod"
                  clearable
                  @input="clearServerError('method')"
                  aria-describedby="select-1-feedback"
                />
                <b-form-invalid-feedback id="select-1-feedback" :class="{'d-block': validateState('method') === false}">
                  <template v-if="serverErrors.method">{{ serverErrors.method[0] }}</template>
                  <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('ORDER.PAYMENTS.METHOD') }) }}</template>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group :label="`${$t('ORDER.PAYMENTS.DATE')}:`" label-for="datepicker">
                <Datepicker id="datepicker"
                            v-model="$v.form.date.$model"
                            :state="validateState('date')"
                            @input="clearServerError('date');"
                            aria-describedby="datepicker-feedback"
                />
                <b-form-invalid-feedback id="datepicker-feedback">
                  <template v-if="serverErrors.dateTime">{{ serverErrors.dateTime[0] }}</template>
                  <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('ORDER.PAYMENTS.DATE') }) }}</template>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group :label="`${$t('ORDER.PAYMENTS.TIME')}:`" label-for="input-5" >
                <b-form-timepicker
                  v-model="$v.form.time.$model"
                />
              </b-form-group>
              <b-form-group :label="`${$t('FORM.COMMENT')}:`" label-for="input-2">
                <Textarea
                  id="input-2"
                  v-model="$v.form.comment.$model"
                  :state="validateState('comment')"
                  :placeholder="$t('PLACEHOLDER.COMMENT')"
                  @input="clearServerError('comment')"
                  aria-describedby="input-2-feedback"
                />
                <b-form-invalid-feedback id="input-2-feedback">
                  <template v-if="serverErrors.comment">{{ serverErrors.comment[0] }}</template>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </div>
    <div class="mt-auto pb-3 pb-sm-0 w-100">
      <b-button class="w-100 d-flex align-items-center justify-content-center btn btn-light-success font-weight-bold btn-sm" @click="onSubmit">
        <span class="svg-icon btn-light-success">
          <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
        </span>
        {{ $t('FORM.SUBMIT') }}
      </b-button>
      <div class="fake-element">
        <!--      IOS fix-->
      </div>
    </div>
  </div>
</Portal>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Repo from '@/core/repository/company/orderPaymentsRepository'
import { FORMAT_TIME, ONLY_DATE } from '@/helpers/validation'
import serverVuelidate from '../../../../mixins/serverVuelidate'
import Textarea from '../../../forms-items/Textarea'
import SelectInfinityScroll from '../../../forms-items/SelectInfinityScroll'
import InputCurrency from '../../../forms-items/inputCurrency'
import Datepicker from '../../../forms-items/Datepicker.vue'

export default {
  name: 'OrderPaymentsForm',
  components: {
    Datepicker,
    InputCurrency,
    SelectInfinityScroll,
    Textarea,
  },
  mixins: [validationMixin, serverVuelidate],
  props: {
    itemEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      form: {
        uuid: '',
        order: this.$route.query.order,
        type: '',
        method: '',
        sum: 0,
        comment: '',
        date: '',
        time: '',
      },
      optionsType: [
        {
          uuid: 'payment',
          name: this.$t('ORDER.PAYMENTS.PAYMENT'),
        },
        {
          uuid: 'prepayment',
          name: this.$t('ORDER.PAYMENTS.PREPAYMENT'),
        },
      ],
      optionsMethod: [
        {
          uuid: 'cash',
          name: this.$t('ORDER.PAYMENTS.CASH'),
        },
        {
          uuid: 'card',
          name: this.$t('ORDER.PAYMENTS.CARD'),
        },
      ],
      searchType: '',
      validations: {
        form: {
          date: { required },
          sum: { required },
          type: { required },
          method: { required },
          comment: { },
          time: { },
        },
      },
    }
  },
  created() {
    if (this.itemEdit && Object.keys(this.itemEdit).length) {
      this.setItem(this.itemEdit)
      this.form.type = this.optionsType.find((f) => f.uuid === this.itemEdit.type)
      this.form.method = this.optionsMethod.find((f) => f.uuid === this.itemEdit.method)
      this.form.date = this.itemEdit.dateTime
      this.form.time = this.itemEdit.dateTime && this.itemEdit.dateTime.split(' ')[1]
    } else {
      this.form.type = this.optionsType[0]
      this.form.method = this.optionsMethod[0]
      this.form.date = this.$moment()
      this.form.time = this.$moment().format(FORMAT_TIME)
    }
  },
  methods: {
    setItem(item) {
      Object.keys(this.form).forEach((propName) => {
        if (
          this.form.hasOwnProperty(propName)
          && item.hasOwnProperty(propName)
        ) {
          if (item[propName] !== null && item[propName] !== undefined) {
            this.form[propName] = item[propName]
          }
        }
      })
    },
    resetForm() {
      this.form = {
        type: 'prepayment',
        method: 'card',
        sum: 0,
        comment: '',
        date: this.$moment(),
        time: '00:00:00',
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.clearServerErrors()
      this.loading = true
      const obj = { ...this.form }
      obj.type = obj.type.uuid
      obj.method = obj.method.uuid
      obj.dateTime = `${this.$moment(obj.date).format(ONLY_DATE)} ${obj.time}`
      delete obj.time
      delete obj.date
      const { uuid, ...rest } = obj
      if (uuid) {
        Repo.patch(uuid, rest).then(() => {
          this.$emit('onSubmit', obj)
          this.resetForm()
          this.loading = false
        }).catch((err) => {
          this.loading = false
          this.seServerErrors(err.response.data)
        })
      } else {
        Repo.post(rest).then((res) => {
          obj.uuid = res.data.payload.uuid
          this.$emit('onSubmit', obj)
          this.resetForm()
          this.loading = false
        }).catch((err) => {
          this.loading = false
          this.seServerErrors(err.response.data)
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
