<template>
<Portal to="OrderDiscountForm">
  <div class="d-flex align-items-end flex-column h-100">
    <div class="w-100">
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <b-container fluid class="p-0">
          <b-row>
            <b-col cols="12">
              <b-form-group :label="`${$t('ORDER.DISCOUNT.TYPE')}:`" label-for="select-1">
                <SelectInfinityScroll
                  id="select-1"
                  v-model="$v.form.type.$model"
                  :search.sync="searchType"
                  :state="validateState('type') === false"
                  :items="optionsType"
                  clearable
                  @input="clearServerError('type')"
                  aria-describedby="select-1-feedback"
                />
                <b-form-invalid-feedback id="select-1-feedback" :class="{'d-block': validateState('type') === false}">
                  <template v-if="serverErrors.type">{{ serverErrors.type[0] }}</template>
                  <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('ORDER.DISCOUNT.TYPE') }) }}</template>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group v-if="form.type && form.type.uuid === 'absolute'" :label="`${$t('ORDER.DISCOUNT.VALUE')}:`" label-for="input-1">
                <InputCurrency
                  id="input-1"
                  v-model="$v.form.value.$model"
                  :state="validateState('value')"
                  :placeholder="$t('PLACEHOLDER.VALUE')"
                  :disabled="!form.type"
                  trim
                  @input="clearServerError('value')"
                  aria-describedby="input-1-feedback"
                />
                <b-form-invalid-feedback id="input-1-feedback">
                  <template v-if="serverErrors.value">{{ serverErrors.value[0] }}</template>
                  <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('ORDER.DISCOUNT.VALUE') }) }}</template>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group v-else :label="`${$t('ORDER.DISCOUNT.VALUE')}:`" label-for="input-4">
                <InputForm
                  id="input-4"
                  v-model="$v.form.value.$model"
                  :state="validateState('value')"
                  :placeholder="$t('PLACEHOLDER.VALUE')"
                  trim
                  :disabled="!form.type"
                  @input="clearServerError('value')"
                  aria-describedby="input-4-feedback"
                />
                <b-form-invalid-feedback id="input-4-feedback">
                  <template v-if="serverErrors.value">{{ serverErrors.value[0] }}</template>
                  <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('ORDER.DISCOUNT.VALUE') }) }}</template>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group :label="`${$t('FORM.COMMENT')}`" label-for="input-2">
                <Textarea
                  id="input-2"
                  v-model="$v.form.comment.$model"
                  :state="validateState('comment')"
                  :placeholder="$t('PLACEHOLDER.COMMENT')"
                  @input="clearServerError('comment')"
                  aria-describedby="input-2-feedback"
                />
                <b-form-invalid-feedback id="input-2-feedback">
                  <template v-if="serverErrors.comment">{{ serverErrors.comment[0] }}</template>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </div>
    <div class="mt-auto pb-3 pb-sm-0 w-100">
      <b-button class="w-100 d-flex align-items-center justify-content-center btn btn-light-success font-weight-bold btn-sm" @click="onSubmit">
        <span class="svg-icon btn-light-success">
          <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
        </span>
        {{ $t('FORM.SUBMIT') }}
      </b-button>
      <div class="fake-element">
        <!--      IOS fix-->
      </div>
    </div>
  </div>
</Portal>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import Repo from '@/core/repository/company/discountRepository'
import serverVuelidate from '../../../../mixins/serverVuelidate'
import Textarea from '../../../forms-items/Textarea'
import SelectInfinityScroll from '../../../forms-items/SelectInfinityScroll'
import InputForm from '../../../forms-items/input'
import InputCurrency from '../../../forms-items/inputCurrency'

export default {
  name: 'OrderDiscountForm',
  components: {
    InputCurrency,
    InputForm,
    SelectInfinityScroll,
    Textarea,
  },
  mixins: [validationMixin, serverVuelidate],
  props: {
    itemEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      form: {
        uuid: '',
        type: null,
        value: '',
        comment: '',
      },
      optionsType: [
        {
          uuid: 'absolute',
          name: this.$t('ORDER.DISCOUNT.ABSOLUTE'),
        },
        {
          uuid: 'percent',
          name: this.$t('ORDER.DISCOUNT.PERCENT'),
        },
      ],
      searchType: '',
      validations: {
        form: {
          type: { required },
          value: { required },
          comment: {},
        },
      },
    }
  },
  created() {
    if (this.itemEdit && Object.keys(this.itemEdit).length) {
      this.setItem(this.itemEdit)
      this.form.type = this.optionsType.find((f) => f.uuid === this.itemEdit.type)
    }
  },
  methods: {
    setItem(item) {
      Object.keys(this.form).forEach((propName) => {
        if (
          this.form.hasOwnProperty(propName)
            && item.hasOwnProperty(propName)
        ) {
          if (item[propName] !== null && item[propName] !== undefined) {
            this.form[propName] = item[propName]
          }
        }
      })
    },
    resetForm() {
      this.form = {
        uuid: '',
        type: null,
        value: '',
        comment: '',
      }
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.clearServerErrors()
      this.loading = true
      const obj = { ...this.form }
      obj.type = obj.type.uuid
      const { uuid, ...rest } = obj
      if (uuid) {
        Repo.patch(uuid, rest).then(() => {
          this.$emit('onSubmit', obj)
          this.resetForm()
          this.loading = false
        }).catch((err) => {
          this.loading = false
          this.seServerErrors(err.response.data)
        })
      } else {
        Repo.post(rest).then((res) => {
          obj.uuid = res.data.payload.uuid
          this.$emit('onSubmit', obj)
          this.resetForm()
          this.loading = false
        }).catch((err) => {
          this.loading = false
          this.seServerErrors(err.response.data)
        })
      }
    },
  },
}
</script>

<style scoped>

</style>
